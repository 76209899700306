$(".filter-label").on("click", function (e) {
    e.preventDefault();

    $(this).siblings(".advanced-filters").toggleClass("expanded");
});
$(".advanced-filter.disabled").on("click",function(e){
    e.preventDefault();
});
function initalizeFilters() {
    var filters = $(".filter-bar");
    filters.each(function () {
        if($(this).find(".advanced-filter.active-filter").length > 0 ){
            $(this).find(".advanced-filters").toggleClass("expanded");
        }
    });
}

$(document).ready(function () {
    initalizeFilters();
});