$("[data-toggle-show]").on("click",function (e) {
    e.preventDefault();
    var target  = $(this).data("toggle-show");
    $(target).toggleClass("show");
});

$("[data-toggle-hide]").on("click",function (e) {
    e.preventDefault();
    var target  = $(this).data("toggle-hide");
    $(target).toggleClass("hide");
});