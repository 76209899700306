$('#nav-toggle').click(function() {
    $(this).toggleClass('expanded');
    $("#navbar-collapse").toggleClass('expanded');

});
function initNav(){
    var items = $(".nav-items > li.expandable > a");

    items.on("click",function(e){
       if(window.matchMedia('(max-width: 1024px)').matches){
           e.preventDefault();
           var item = $(this);
           items.each(function () {
               if(item.get(0) !== this){
                   $(this).closest("li").removeClass("open");
               }
           });
            $(this).closest("li").toggleClass("open");
        }
    });
}
initNav();

$(window).scroll(function(){
    if ($(this).scrollTop() > $("nav.navbar").position().top && $(this).scrollTop() >= $(".quick-access").height() && $(window).width() >= 1007)  {
        $("nav.navbar").addClass('fixed');
        $("header").css("margin-bottom",$("nav.navbar").height() - 10);
    } else {
        $("nav.navbar").removeClass('fixed');
        $("header").css("margin-bottom",'0');
    }
});