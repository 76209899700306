$("a.dropdown").on("click",function(e){
    e.preventDefault();
    event.stopPropagation();
    $(this).toggleClass("active");
});

$(document).ready(function () {
    var anchors = $(".left-nav a.dropdown");

    anchors.each(function (el) {
        if($(this).next().find("a.active").length > 0){
            $(this).addClass("active");
        }
    })
});